<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>

        <div class="mb-3">
          <v-toolbar>
            <v-toolbar-title>Issuance</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="6">
                <v-card>
                <v-toolbar dark class="panel1">
                    <v-toolbar-title>Info</v-toolbar-title>
                </v-toolbar>             
                <v-container class="mt-3">
                    <v-form ref="searchForm">
                        <v-text-field 
                            label="Member Id / Cell Phone / Card No" 
                            outlined
                            dense
                            v-model="search_word"  
                            append-outer-icon="mdi-magnify"
                            :append-icon="custExist ?'mdi-check':''"
                            @click:append-outer="searchCustomer"
                            :rules="[rules.required]"

                        ></v-text-field>
                    </v-form>
                    <v-form ref="mainForm">
                        <v-select
                            ref="vsMerchant"
                            label="Merchant"
                            outlined
                            dense
                            :items="dataList['merchantList']"
                            item-text="merchant_name"
                            item-value="merchant_id"
                            v-model = "form.merchant_id"
                            @change="this.form.edc_login_id=''"
                        ></v-select>
                        <v-select
                            ref="vsOutlet"                            
                            label="EDC"
                            outlined
                            dense
                            :items="edcListFiltered"
                            item-text="outlet_name"
                            item-value="edc_login_id"
                            v-model = "form.edc_login_id"
                        ></v-select>
                        <v-select
                            label="Code"
                            outlined
                            dense
                            :items="egListFiltered"
                            item-text="eg_name"
                            item-value="eg_code"
                            v-model = "form.eg_code"
                        ></v-select>
                        <v-menu v-model="dpicker.menu" :close-on-content-click="false" max-width="290">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    outlined
                                    dense
                                    :value="form.transaction_date"
                                    label="Transaction Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="form.transaction_date" @change="dpicker.menu = false"></v-date-picker>
                        </v-menu>

                        <v-text-field 
                            label="Purchase Amount / Points" 
                            outlined
                            dense
                            v-model="form.amount"  
                        ></v-text-field>
                        <v-text-field 
                            label="Reference No." 
                            outlined 
                            dense
                            v-model="form.reference_no" 
                        ></v-text-field>
                    </v-form>

                </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cbtnSave" dark @click="save">
                    <v-icon left dark>mdi-check</v-icon>
                    Submit
                </v-btn>
            </v-card-actions>

            </v-card>
            </v-col>

            <v-col cols="12" md="6">
                <v-card>
                    <v-toolbar dark color="blue darken-4">
                        <v-toolbar-title>Customer Info</v-toolbar-title>
                    </v-toolbar>
                <v-container class="mt-3">                    
                    <v-list>
                        <template v-for="(value,index) in custInfo">
                            <v-list-item :key="'custInfo-' + index">                        
                                <v-list-item-icon>
                                    <v-icon v-text="value.icon"></v-icon>
                                </v-list-item-icon>                            
                                <v-list-item-content>
                                    <v-list-item-title>{{custData[value.name]}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        </v-list>                    
                </v-container>
                </v-card>
                <v-card class="mt-3">
                    <v-toolbar dark  color="blue darken-3">
                        <v-toolbar-title>Issuance Log</v-toolbar-title>
                    </v-toolbar>
                    <v-container class="mt-3">                    
                        <v-list>
                            <template v-for="(log,index) in logs">
                            <v-list-item four-line :key="'key-' + index">
                                <v-list-item-content>
                                    <v-list-item-title>{{log.process_reference_no}}</v-list-item-title>
                                    <v-list-item-subtitle>
                                    {{log.login_id}}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                    {{log.merchant_name}} > {{log.outlet_name}} > {{log.edc_login_id}}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                    {{log.reference_no}} > {{log.transaction_date}} > {{log.eg_code}} > {{log.amount}} > {{log.points}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                </v-list-item>
                                <v-divider  :key="'keyd-' + index"></v-divider>
                                </template>
                                
                        </v-list>
                    </v-container>
                </v-card>


            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApTransactionService from "@/services/ApTransactionService"
import ApCustomerService from "@/services/ApCustomerService"
import moment from 'moment'
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Transactions',disabled: false,href:'',},
              {text:'Issuance',disabled: false,href:'#',},
              ],
            dataList:{edcList:[],egEdcList:[]},           
            search_word:"",
            form:{
                login_id:"",
                customer_id:0,
                eg_code:"",
                merchant_id:0,
                edc_login_id: "",
                transaction_date: "",
                reference_no: "",
                amount: 0,
                quantity: 0,
            },
            custData:[],

            logo:'',
            custExist:false,
            dpicker:{menu1: false,menu2: false,},
            rules: {
                required: value => !!value || 'Required.',
                counter: value => value.length == 6 || 'Need 6 digit',
                digit: value=> /[0-9][0-9][0-9][0-9][0-9][0-9]/.test(value) || 'Number only',
              },
            logs:[],
            custInfo: [
                    { name: 'full_name', icon: 'mdi-card-account-details' },
                    { name: 'login_id', icon: 'mdi-account' },
                    { name: 'cell_phone', icon: 'mdi-phone' },
                    { name: 'mbr_card_no', icon: 'mdi-credit-card' },
                    { name: 'point_balance', icon: 'mdi-plus-circle-multiple' },
                ],            


    }
  },
  async created(){
      this.form.transaction_date = moment(String(new Date())).format('YYYY-MM-DD');
        try{
            ApTransactionService.get_issuance_parameter(this.form).then((res) => {
                this.dataList = res.data;
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Manual Point Issuance',e.response.data.error_message);
            })
            ;
        } catch(e) {
            if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error','Manual Point Issuance','Unknown Error');
            
        } 
  },
  computed:{
      edcListFiltered(){
          return this.dataList['edcList'].filter(item => item.merchant_id === this.form.merchant_id)
      },
      egListFiltered(){          
          return this.dataList['egEdcList'].filter(item => item.edc_login_id === this.form.edc_login_id)
      }

  },

  
  methods:{

    dlgClose(){
        this.visible = false;        
    },
    async searchCustomer(){
        this.form.search_word = this.search_word;
        
         if(this.$refs.searchForm.validate()) {
            try{
                ApCustomerService.search_customer_custom(this.form).then((res) => {
                    this.custExist = true;
                    this.form.customer_id = res.data.data[0].customer_id;
                    this.custData = res.data.data[0];
                }).catch((e)=>{
                    if(e)
                        this.$refs.dlgMsg.showDlgBox(true,'error','Manual Point Issuance',e.response.data.error_message);
                })
                ;
            } catch(e) {
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error','Manual Point Issuance','Unknown Error');
                
            } 
        }
    },

    async save(){
        if(this.$refs.mainForm.validate()) {
            try{
                ApTransactionService.issue_point(this.form).then((res) => {
                    let log = [];
                    log.process_reference_no = res.data.process_reference_no;
                    log.login_id = this.custData.login_id;
                    log.amount = this.form.amount;
                    log.transaction_date = this.form.transaction_date;
                    log.eg_code = this.form.eg_code;
                    log.reference_no = this.form.reference_no;
                    log.merchant_name = this.$refs.vsMerchant.selectedItems[0].merchant_name;
                    log.outlet_name = this.$refs.vsOutlet.selectedItems[0].outlet_name;
                    log.edc_login_id = this.form.edc_login_id;
                    log.points = res.data.points;
                    this.logs.unshift(log);
                    this.$refs.dlgMsg.showDlgBox(true,'success','Manual Point Issuance','Success. Point earned:' + res.data.points);
                }).catch((e)=>{
                    if(e)
                        this.$refs.dlgMsg.showDlgBox(true,'error','Manual Point Issuance',e.response.data.error_message);
                })
                ;
            } catch(e) {
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error','Manual Point Issuance','Unknown Error');                
            } 

        }

    }

  }
}
</script>